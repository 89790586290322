import Vue from 'vue'
import VueRouter from 'vue-router'

/**
 * 重写路由的push方法
 * 解决，相同路由跳转时，报错
 * 添加，相同路由跳转时，触发watch (针对el-menu，仅限string方式传参，形如"view?id=5")
 */

// 保存原来的push函数
const routerPush = VueRouter.prototype.push
// 重写push函数
VueRouter.prototype.push = function push(location) {

  // 这个if语句在跳转相同路径的时候，在路径末尾添加新参数（一些随机数字）
  // 用来触发watch
  if (typeof (location) == "string") {
    var Separator = "&";
    if (location.indexOf('?') == -1) {
      Separator = '?';
    }
    location = location + Separator + "random=" + Math.random();
  }

  // 这个语句用来解决报错
  // 调用原来的push函数，并捕获异常
  return routerPush.call(this, location).catch(error => error)
}


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/index'
  },{
    path: '/index',
    name: 'index',
    meta: {
      title: '首页',
    },
    component: () => import( /* webpackChunkName: "index" */ '../views/index.vue')
  },
  {
    path: '/guide-list',
    name: 'GuideList',
    meta: {
      title: '指南列表',
    },
    component: () => import( /* webpackChunkName: "GuideList" */ '../views/guide-list.vue')
  },
  {
    path: '/guide-list-detail',
    name: 'GuideListDetail',
    meta: {
      title: '指南详情',
    },
    component: () => import( /* webpackChunkName: "GuideListDetail" */ '../views/guide-list-detail.vue')
  },
  {
    path: '/meeting-list',
    name: 'MeetingList',
    meta: {
      title: '会议列表',
    },
    component: () => import( /* webpackChunkName: "MeetingList" */ '../views/meeting-list.vue')
  },
  {
    path: '/live-list',
    name: 'LiveList',
    meta: {
      title: '直播列表',
    },
    component: () => import( /* webpackChunkName: "LiveList" */ '../views/live-list.vue')
  },
  {
    path: '/video-class',
    name: 'VideoClass',
    meta: {
      title: '视频分类',
    },
    component: () => import( /* webpackChunkName: "VideoClass" */ '../views/video-class.vue')
  },
  {
    path: '/video-list',
    name: 'VideoList',
    meta: {
      title: '视频列表',
    },
    component: () => import( /* webpackChunkName: "VideoList" */ '../views/video-list.vue')
  },
  {
    path: '/meeting-video-list',
    name: 'MeetingVideoList',
    meta: {
      title: '会议视频列表',
    },
    component: () => import( /* webpackChunkName: "MeetingVideoList" */ '../views/meeting-video-list.vue')
  },
  {
    path: '/video-list-detail',
    name: 'VideoListDetail',
    meta: {
      title: '视频信息',
    },
    component: () => import( /* webpackChunkName: "VideoListDetail" */ '../views/video-list-detail.vue')
  },
  {
    path: '/demo',
    name: 'demo',
    component: () => import( /* webpackChunkName: "demo" */ '../views/demo/index.vue')
  },
  {
    path: '/database/search',
    name: 'DatabaseSearch',
    meta: {
      title: '中国医学学术会议论文摘要数据库',
    },
    component: () => import( /* webpackChunkName: "DatabaseSearch" */ '../views/database/search.vue')
  },
  {
    path: '/database/index',
    name: 'DatabaseIndex',
    meta: {
      title: '摘要数据库检索内容',
    },
    component: () => import( /* webpackChunkName: "DatabaseIndex" */ '../views/database/index.vue')
  },
  {
    path: '/database/advanced-search',
    name: 'AdvancedSearch',
    meta: {
      title: '高级检索',
    },
    component: () => import( /* webpackChunkName: "AdvancedSearch" */ '../views/database/advanced-search.vue')
  },
  {
    path: '/database/database-detail',
    name: 'DatabaseDetail',
    meta: {
      title: '摘要详情',
    },
    component: () => import( /* webpackChunkName: "DatabaseDetail" */ '../views/database/database-detail.vue')
  },
  {
    path: '/journal-article',
    name: 'JournalArticle',
    meta: {
      title: '期刊论文',
    },
    component: () => import( /* webpackChunkName: "JournalArticle" */ '../views/journalArticle/index.vue')
  },
  {
    path: '/periodical-list',
    name: 'PeriodicalList',
    meta: {
      title: '期刊杂志',
    },
    component: () => import( /* webpackChunkName: "PeriodicalList" */ '../views/journalArticle/periodical-list.vue')
  },
  {
    path: '/periodical-detail',
    name: 'PeriodicalDetail',
    meta: {
      title: '杂志列表',
    },
    component: () => import( /* webpackChunkName: "PeriodicalDetail" */ '../views/journalArticle/periodical-detail.vue')
  },
  {
    path: '/paper-detail',
    name: 'PaperDetail',
    meta: {
      title: '杂志详情',
    },
    component: () => import( /* webpackChunkName: "PaperDetail" */ '../views/journalArticle/paper-detail.vue')
  },
  {
    path: '/approximation-list',
    name: 'ApproximationList',
    meta: {
      title: '期刊约稿',
    },
    component: () => import( /* webpackChunkName: "ApproximationList" */ '../views/journalArticle/approximation-list.vue')
  },
  {
    path: '/meetpaper-list',
    name: 'MeetPaperList',
    meta: {
      title: '会议论文',
    },
    component: () => import( /* webpackChunkName: "MeetPaperList" */ '../views/journalArticle/meetpaper-list.vue')
  },
  {
    path: '/article-list',
    name: 'ArticleList',
    meta: {
      title: '会议相关论文列表',
    },
    component: () => import( /* webpackChunkName: "ArticleList" */ '../views/journalArticle/article-list.vue')
  },
  {
    path: '/article-detail',
    name: 'ArticleDetail',
    meta: {
      title: '会议相关论文列表',
    },
    component: () => import( /* webpackChunkName: "ArticleDetail" */ '../views/journalArticle/article-detail.vue')
  },
  {
    path: '/expert/index',
    name: 'ExpertIndex',
    meta: {
      title: '专家风采',
    },
    component: () => import( /* webpackChunkName: "ExpertIndex" */ '../views/expert/index.vue')
  },
  {
    path: '/expert/user-search',
    name: 'ExpertSearch',
    meta: {
      title: '专家检索',
    },
    component: () => import( /* webpackChunkName: "ExpertSearch" */ '../views/expert/user-search.vue')
  },
  {
    path: '/expert/follow',
    name: 'ExpertFollow',
    meta: {
      title: '我的关注',
    },
    component: () => import( /* webpackChunkName: "ExpertFollow" */ '../views/expert/follow.vue')
  },
  {
    path: '/person-detail',
    name: 'PersonDetail',
    meta: {
      title: '专家详情',
    },
    component: () => import( /* webpackChunkName: "PersonDetail" */ '../views/expert/user-detail.vue')
  },
  {
    path: '/expert/ys-mien',
    name: 'ExpertYsMien',
    meta: {
      title: '院士风采',
    },
    component: () => import( /* webpackChunkName: "ExpertYsMien" */ '../views/expert/ys-mien.vue')
  },
  {
    path: '/expert/committee',
    name: 'ExpertCommittee',
    meta: {
      title: '学科主委',
    },
    component: () => import( /* webpackChunkName: "ExpertCommittee" */ '../views/expert/committee')
  },
  {
    path: '/expert/subject-search',
    name: 'ExpertSubjectSearch',
    meta: {
      title: '学科主委详情',
    },
    component: () => import( /* webpackChunkName: "ExpertSubjectSearch" */ '../views/expert/subject-search')
  },
  {
    path: '/expert/subject-details',
    name: 'ExpertSubjectDetails',
    meta: {
      title: '学科委员人员列表',
    },
    component: () => import( /* webpackChunkName: "ExpertSubjectDetails" */ '../views/expert/subject-details')
  },
  {

    path: '/special-list',
    name: 'SpecialList',
    meta: {
      title: '专题列表',
    },
    component: () => import( /* webpackChunkName: "SpecialList" */ '../views/special-list.vue')
  },
  {

    path: '/special-detail',
    name: 'SpecialDetail',
    meta: {
      title: '专题详情',
    },
    component: () => import( /* webpackChunkName: "SpecialList" */ '../views/special-detail.vue')
  },
  // {

  //   path: '/course-list',
  //   name: 'CourseList',
  //   meta:{
  //     title:'精品课堂',
  //   },
  //   component: () => import(/* webpackChunkName: "CourseList" */ '../views/course-list.vue')
  // },
  // {

  //   path: '/course-detail',
  //   name: 'CourseDetail',
  //   meta:{
  //     title:'课堂详情',
  //   },
  //   component: () => import(/* webpackChunkName: "CourseDetail" */ '../views/course-detail.vue')
  // },
  {
    path: '/infomation-list',
    name: 'InfomationList',
    meta: {
      title: '热点资讯',
    },
    component: () => import( /* webpackChunkName: "InfomationList" */ '../views/infomation-list.vue')
  },
  {
    path: '/infomation-detail',
    name: 'InfomationDetail',
    meta: {
      title: '资讯详情',
    },
    component: () => import( /* webpackChunkName: "InfomationDetail" */ '../views/infomation-detail.vue')
  },
  {
    path: '/meeting-certificate',
    name: 'MeetingCertificate',
    meta: {
      title: '继教学分证书下载',
    },
    component: () => import( /* webpackChunkName: "MeetingCertificate" */ '../views/meeting-certificate.vue')
  },
  // 机构号
  {
      path: '/organization/home',
      name: 'organizationNewIndex',
      mate: {
        title: '机构号'
      },
      component: () => import('../views/organization/home')
  },
  {
    path: '/organization/index',
    name: 'organizationIndex',
    meta: {
      title: '机构号列表',
    },
    component: () => import( /* webpackChunkName: "organizationIndex" */ '../views/organization/index')
  },
  {
    path: '/organization/org-homepage',
    name: 'orgHomePage',
    meta: {
      title: '机构号主页',
    },
    component: () => import( /* webpackChunkName: "orgHomePage" */ '../views/organization/org-homepage')
  },
  {
    path: '/organization/org-infomation',
    name: 'orgInfomation',
    meta: {
      title: '机构号资讯列表',
    },
    component: () => import( /* webpackChunkName: "orgInfomation" */ '../views/organization/org-infomation')
  },
  {
    path: '/organization/org-info-detail',
    name: 'orgInfoDetail',
    meta: {
      title: '机构号资讯详情',
    },
    component: () => import( /* webpackChunkName: "orgInfoDetail" */ '../views/organization/org-info-detail')
  },
  {
    path: '/organization/org-meeting',
    name: 'orgMeeting',
    meta: {
      title: '机构号会议列表',
    },
    component: () => import( /* webpackChunkName: "orgMeeting" */ '../views/organization/org-meeting')
  },
  {
    path: '/organization/org-live',
    name: 'orgLive',
    meta: {
      title: '机构号直播列表',
    },
    component: () => import( /* webpackChunkName: "orgLive" */ '../views/organization/org-live')
  },
  {
    path: '/organization/org-playback',
    name: 'orgPlayback',
    meta: {
      title: '机构号直播列表',
    },
    component: () => import( /* webpackChunkName: "orgPlayback" */ '../views/organization/org-playback')
  },
  {
    path: '/organization/org-album',
    name: 'orgAlbum',
    meta: {
      title: '机构号相册列表',
    },
    component: () => import( /* webpackChunkName: "orgAlbum" */ '../views/organization/org-album')
  },
  {
    path: '/organization/org-album-detail',
    name: 'orgAlbumDetail',
    meta: {
      title: '机构号相册详情',
    },
    component: () => import( /* webpackChunkName: "orgAlbumDetail" */ '../views/organization/org-album-detail')
  },
  {
    path: '/open-in-mobile',
    name: 'openInMobile',
    meta: {
      title: '',
    },
    component: () => import( /* webpackChunkName: "openInMobile" */ '../views/open-in-mobile')
  },
  // 企业号
  {
    path: '/company/home',
    name: 'companyNewIndex',
    mate: {
      title: '医企秀'
    },
    component: () => import('../views/company/home')
  },
  {
    path: '/company/list',
    name: 'CompanyList',
    meta: {
      title: '医企秀',
    },
    component: () => import( /* webpackChunkName: "openInMobile" */ '../views/company/list')
  },
  {
    path: '/company/index',
    name: 'CompanyIndex',
    meta: {
      title: '企业主页',
    },
    component: () => import( /* webpackChunkName: "openInMobile" */ '../views/company/index')
  },
  {
    path: '/company/news',
    name: 'CompanyNews',
    meta: {
      title: '企业资讯',
    },
    component: () => import( /* webpackChunkName: "openInMobile" */ '../views/company/news')
  },
  {
    path: '/company/news-detail',
    name: 'CompanyNewsDetail',
    meta: {
      title: '企业资讯详情',
    },
    component: () => import( /* webpackChunkName: "openInMobile" */ '../views/company/news-detail')
  },
  {
    path: '/company/product',
    name: 'CompanyProduct',
    meta: {
      title: '企业产品',
    },
    component: () => import( /* webpackChunkName: "openInMobile" */ '../views/company/product')
  },
  {
    path: '/company/product-list',
    name: 'CompanyProductList',
    meta: {
      title: '企业产品列表',
    },
    component: () => import( /* webpackChunkName: "openInMobile" */ '../views/company/product-list')
  },
  {
    path: '/company/meeting',
    name: 'CompanyMeeting',
    meta: {
      title: '企业会议',
    },
    component: () => import( /* webpackChunkName: "openInMobile" */ '../views/company/meeting')
  },
  {
    path: '/company/live',
    name: 'CompanyLive',
    meta: {
      title: '企业直播',
    },
    component: () => import( /* webpackChunkName: "openInMobile" */ '../views/company/live')
  },
  {
    path: '/company/video',
    name: 'CompanyVideo',
    meta: {
      title: '企业视频',
    },
    component: () => import( /* webpackChunkName: "openInMobile" */ '../views/company/video')
  },
  {
    path: '/company/invite',
    name: 'CompanyInvite',
    meta: {
      title: '企业招聘',
    },
    component: () => import( /* webpackChunkName: "openInMobile" */ '../views/company/invite')
  },
  {
    path: '/company/enter',
    name: 'CompanyEnter',
    meta: {
      title: '企业入驻',
    },
    component: () => import( /* webpackChunkName: "openInMobile" */ '../views/company/enter')
  },
  // 综合专题
  {
    path: '/special-ma/list',
    name: 'SpecialMaList',
    meta: {
      title: '学术专题',
    },
    component: () => import( /* webpackChunkName: "SpecialMaModelConfig" */ '../views/special-ma/list.vue')
  },
  // 首页配置页面
  {
    path: '/special-ma/model-config/:id',
    name: 'SpecialMaModelConfig',
    meta: {
      title: '首页',
    },
    component: () => import( /* webpackChunkName: "SpecialMaModelConfig" */ '../views/special-ma/model-config.vue')
  },
  {
    path: '/special-ma/index/:id',
    name: 'SpecialMaIndex',
    meta: {
      title: '首页',
    },
    component: () => import( /* webpackChunkName: "SpecialMaIndex" */ '../views/special-ma/index.vue')
  },
  {
    path: '/special-ma/infomation-list/:id',
    name: 'SpecialMaInfoList',
    meta: {
      title: '热点资讯',
    },
    component: () => import( /* webpackChunkName: "SpecialMaInfoList" */ '../views/special-ma/special-infomation-list.vue')
  },
  {
    path: '/special-ma/infomation-detail/:id',
    name: 'SpecialMaInfoDetail',
    meta: {
      title: '热点资讯详情',
    },
    component: () => import( /* webpackChunkName: "SpecialMaInfoDetail" */ '../views/special-ma/special-infomation-detail.vue')
  },
  {
    path: '/special-ma/video-list/:id',
    name: 'SpecialMaVideoList',
    meta: {
      title: '相关视频',
    },
    component: () => import( /* webpackChunkName: "SpecialMaVideoList" */ '../views/special-ma/special-video-list.vue')
  },
  {
    path: '/special-ma/video-detail/:id',
    name: 'SpecialMaVideoDetail',
    meta: {
      title: '视频详情',
    },
    component: () => import( /* webpackChunkName: "SpecialMaVideoDetail" */ '../views/special-ma/special-video-detail.vue')
  },
  {
    path: '/special-ma/guide-list/:id',
    name: 'SpecialMaGuideList',
    meta: {
      title: '相关指南',
    },
    component: () => import( /* webpackChunkName: "SpecialMaGuideList" */ '../views/special-ma/special-guide-list.vue')
  },
  {
    path: '/special-ma/guide-detail/:id',
    name: 'SpecialMaGuideDetail',
    meta: {
      title: '指南详情',
    },
    component: () => import( /* webpackChunkName: "SpecialMaGuideDetail" */ '../views/special-ma/special-guide-detail.vue')
  },
  {
    path: '/special-ma/periodical-list/:id',
    name: 'SpecialMaPeriodicalList',
    meta: {
      title: '推荐期刊',
    },
    component: () => import( /* webpackChunkName: "SpecialMaPeriodicalList" */ '../views/special-ma/special-periodical-list.vue')
  },
  {
    path: '/special-ma/periodical-detail/:id',
    name: 'SpecialMaPeriodicalDetail',
    meta: {
      title: '期刊详情',
    },
    component: () => import( /* webpackChunkName: "SpecialMaPeriodicalDetail" */ '../views/special-ma/special-periodical-detail.vue')
  },
  {
    path: '/special-ma/paper-detail/:id',
    name: 'SpecialMaPaperDetail',
    meta: {
      title: '文章详情',
    },
    component: () => import( /* webpackChunkName: "SpecialMaPeriodicalDetail" */ '../views/special-ma/special-paper-detail.vue')
  },
  {
    path: '/special-ma/meeting-list/:id',
    name: 'SpecialMaMeetingList',
    meta: {
      title: '相关会议',
    },
    component: () => import( /* webpackChunkName: "SpecialMaMeetingList" */ '../views/special-ma/special-meeting-list.vue')
  },
  {
    path: '/special-ma/expert-list/:id',
    name: 'SpecialMaExpertList',
    meta: {
      title: '推荐专家',
    },
    component: () => import( /* webpackChunkName: "SpecialMaExpertList" */ '../views/special-ma/special-expert-list.vue')
  },
  {
    path: '/special-ma/user-detail/:id',
    name: 'SpecialMaUserDetail',
    meta: {
      title: '专家详情',
    },
    component: () => import( /* webpackChunkName: "SpecialMaUserDetail" */ '../views/special-ma/special-user-detail.vue')
  },
  {
    path: '/special-ma/org-list/:id',
    name: 'SpecialMaOrgList',
    meta: {
      title: '推荐机构',
    },
    component: () => import( /* webpackChunkName: "SpecialMaOrgList" */ '../views/special-ma/special-org-list.vue')
  },
  {
    path: '/special-ma/org-detail/:id',
    name: 'SpecialMaOrgDetail',
    meta: {
      title: '机构详情',
    },
    component: () => import( /* webpackChunkName: "SpecialMaOrgDetail" */ '../views/special-ma/special-org-detail.vue')
  },
  {
    path: '/special-ma/article-list/:id',
    name: 'SpecialMaArticleList',
    meta: {
      title: '相关论文',
    },
    component: () => import( /* webpackChunkName: "SpecialMaArticleList" */ '../views/special-ma/special-article-list.vue')
  },
  {
    path: '/special-ma/article-detail/:id',
    name: 'SpecialMaArticleDetail',
    meta: {
      title: '论文详情',
    },
    component: () => import( /* webpackChunkName: "SpecialMaArticleDetail" */ '../views/special-ma/special-article-detail.vue')
  },
  //省市区委员任职库
  {
    path: '/job-warehouse/index',
    name: 'JobWarehouseIndex',
    meta: {
        title: '省市医学会委员任职库',
    },
    component: () => import( /* webpackChunkName: "JobWarehouseIndex" */ '../views/jobWarehouse/index.vue')
  },
  {
    path: "/job-warehouse/expertHome",
    name: "expertHome",
    meta: {
      title: '搜专家首页'
    },
    component: () => import( /* webpackChunkName: "expertHome" */ '../views/jobWarehouse/expertHome.vue')
  },
  {
    path: '/job-warehouse/branchHome',
    name: 'branchHome',
    meta: {
      title: '搜学会'
    },
    component: () => import( /* webpackChunkName: "branchHome" */ '../views/jobWarehouse/branchHome.vue')
  },
  {
    path: '/job-warehouse/surnameList',
    name: 'surnameList',
    meta : {
      title: '届次'
    },
    component: () => import( /* webpackChunkName: "surnameList" */ '../views/jobWarehouse/surnameList.vue')
  },
  {
    path: '/job-warehouse/rollCall',
    name: 'rollCall',
    meta: {
      title: '名单'
    },
    component: () => import( /* webpackChunkName: "rollCall" */ '../views/jobWarehouse/rollCall.vue')
  },
  {
    path: '/job-warehouse/branchVenueHome',
    name: 'branchVenueHome',
    meta: {
      title: '搜分会'
    },
    component: () => import( /* webpackChunkName: "branchVenueHome" */ '../views/jobWarehouse/branchVenueHome.vue')
  },
  {
    path: '/course/index',
    name: 'courseIndex',
    meta: {
      title: '视频列表'
    },
    component: () => import('../views/course-module/index.vue')
  },
  {
    path: '/course/pay',
    name: 'pay',
    meta: {
      title: '视频购买'
    },
    component: () => import('../views/course-module/pay.vue')
  },
  {
    path: '/course/classroom-details',
    name: 'classroomDetails',
    meta: {
      title: '课堂详情'
    },
    component: () => import('../views/course-module/classroom-details.vue')
  },
  {
    path: '/course/classroom-play',
    name: 'classroomPlay',
    meta: {
      title: '课堂视频'
    },
    component: () => import('../views/course-module/classroom-play.vue')
  },
  {
    path: '/course/play',
    name: 'play',
    meta: {
      title: '视频播放'
    },
    component: () => import('../views/course-module/play.vue')
  },
  {
    path : '/course/mobile-auth',
    name : 'mobileAuth',
    meta : {
      title : '授权'
    },
    component : () => import('../views/course-module/mobile-auth.vue')
  },
  //   科技奖
  {
    path: '/awards/index',
    name: 'awardsIndex',
    meta: {
        title: '科技奖'
    },
    component: () => import( /* webpackChunkName: "awardsIndex" */ '../views/awards/index.vue')
  },
  {
    path: '/awards/search-result',
    name: 'awardsSearchResult',
    meta: {
      title: '科技奖搜索结果'
    },
    component: () => import( /* webpackChunkName: "awardsSearchResult" */ '../views/awards/search-result.vue')
  },
  {
    path: "/:carchAll(.*)",
    name: "/404",
    component: () => import("../views/404"),
  },
]

const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  routes
})

export default router