import router from "./router";
import storage from 'store'
import store from '@/store/index'
import * as request from '@/request/common'
import { setDocumentTitle } from '@/utils/domUtil'

let isLogin = true;

router.beforeEach((to, from, next) => {
    // 设置标题|描述|关键字
    if (to.meta && typeof to.meta.title !== 'undefined') {
        setDocumentTitle(to.meta.title);
    }
    // 如果参数中存在unified则代表需要登录
    if (to.query.unified && !store.state.userInfo?.user_id) {
        request.post('UnifiedLogin', {
            unified: to.query.unified
        }).then(res => {
            storage.set('auth', res.data);
            request.get('GetUserInfoByUserId').then(res => {
                store.commit('updateState', { userInfo: res.data });
                // 存储学科ids
                let operate_id = res.data.operate_subject
                if (!storage.get('operate_id') || storage.get('operate_id') != operate_id) {
                    storage.set('operate_id', res.data.operate_subject)
                    request.get('NewSubject').then(res => {
                        let subjectIds = res.data.filter(item => { return item.id == operate_id })
                        storage.set('subjectIds', subjectIds[0].subject_ids)
                    })
                }

                let query = JSON.parse(JSON.stringify(to.query));
                query.unified && (delete query.unified);
                setTimeout(() => {
                    next({
                        path: to.path,
                        replace: true,
                        query
                    })
                }, 1000)
            })
        })
    } else {
        if (!store.state.userInfo?.user_id && isLogin) {
            request.get('GetUserInfoByUserId', {}, { hideModal: true }).then(res => {
                store.commit('updateState', { userInfo: res.data });
                let operate_id = res.data.operate_subject
                if (!storage.get('operate_id') || storage.get('operate_id') != operate_id) {
                    storage.set('operate_id', res.data.operate_subject)
                    request.get('NewSubject').then(res => {
                        let subjectIds = res.data.filter(item => { return item.id == operate_id })
                        storage.set('subjectIds', subjectIds[0].subject_ids)
                    })
                }
                setTimeout(() => {
                    next();
                }, 1000)

            }).catch((err) => {
                isLogin = false;
                next()
            })
        } else {
            next()
        }
    }
})

router.afterEach((to, from) => {
    // 因为列表页加了缓存，故每次切换页面时都需要做一个置顶操作
    window.scrollTo && window.scrollTo(0, 0);
})