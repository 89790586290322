import Vue from 'vue'

// url过滤器 
const urlFilter = (url, s, type) => {
	var baseUrl = 'https://files.sciconf.cn';
	if (!url) {
        if(type == 'avatar'){
            url = ''
        }else{
            url = 'https://files.sciconf.cn/mpweixin/dc1bd080-23e7-11eb-8a36-ebb87efcf8c0.jpg!wx360';
        }
	} else if(url.indexOf('gif') != '-1') {
		//gif格式不加后缀
		return url;
	} else if (url.indexOf('wxfile:/') == -1) {
		url = url.replace('!avatar', '')
		url = url.replace('!wx250', '')
		url = url.replace('!img', '')
		if (url.indexOf('http') != -1) { // 全路径
			let ossFile = 'https://meetingfiles.oss-cn-qingdao.aliyuncs.com/';
			// 全路径拼接重复问题
			if(url.indexOf(ossFile + 'https') != -1){
				url = url.replace(ossFile,'');
			}else{
				if(url.indexOf('!wx') != -1){
					url = url;
				}else{
					url = url.indexOf(baseUrl) == -1 ? url : url + ('!wx' + s);
				}
			}
		} else if (url.indexOf('../') != -1) { // 本地图片
			url = url;
		} else if (url.indexOf('./') != -1) { // 相对路径
			url = baseUrl + url.replace('./', '') + ('!wx' + s);
		} else { // 
			url = baseUrl + '/' + url + ('!wx' + s);
		}
	}
	// 使用压缩图
	return url
}

//时间过滤器
const formatDate = (date, fmt) => {
	date = date || ''
	date = (typeof date == 'number') ? date : new Date(date.toString().replace(new RegExp('-', 'g'), '/'))
	date = date ? date : new Date()
	date = (typeof date == 'number') ? new Date(date) : date
	fmt = fmt || 'yyyy-MM-dd HH:mm:ss'
	var obj = {
		y: date.getFullYear(), // 年份，注意必须用getFullYear
		M: date.getMonth() + 1, // 月份，注意是从0-11
		d: date.getDate(), // 日期
		q: Math.floor((date.getMonth() + 3) / 3), // 季度
		w: date.getDay(), // 星期，注意是0-6
		H: date.getHours(), // 24小时制
		h: date.getHours() % 12 == 0 ? 12 : date.getHours() % 12, // 12小时制
		m: date.getMinutes(), // 分钟
		s: date.getSeconds(), // 秒
		S: date.getMilliseconds(), // 毫秒
	}
	var week = ['日', '一', '二', '三', '四', '五', '六']
	var objKeys = ['y', 'M', 'd', 'q', 'w', 'H', 'h', 'm', 's', 'S']
	for (var i = 0; i < objKeys.length; i++) {
		var key = objKeys[i]
		fmt = fmt.replace(new RegExp(key + '+', 'g'), function(m) {
			var val = obj[key] + ''
			if (key == 'w') return (m.length > 2 ? '星期' : '周') + week[val]
			var len = val.length
			for (var j = 0; j < m.length - len; j++) val = '0' + val
			return m.length == 1 ? val : val.substring(val.length - m.length)
		})
	}
	return fmt
}

//秒转 时:分:秒
const secondTohhmmss = (data) => {
	data = data / 1000
	var hours = Math.floor(data / 3600) > 9 ? Math.floor(data / 3600) : `0${Math.floor(data / 3600)}`;
	var minutes = Math.floor((data % 3600) / 60) > 9 ? Math.floor((data % 3600) / 60) : `0${Math.floor((data % 3600) / 60)}`;
	var remainingSeconds = (data % 60) > 9 ? (data % 60) : `0${(data % 60)}`;
	return `${hours}:${minutes}:${remainingSeconds}`
}

Vue.filter('secondTohhmmss', secondTohhmmss)
Vue.filter('formatDate',formatDate);
Vue.filter('urlFilter',urlFilter);



