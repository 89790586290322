// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);

// 路由拦截器
require('@/permission');

// 引入全局样式
import '@/assets/css/app.less';
// 全局过滤器
require('@/utils/filter');
// 全局拦截去
import * as request from '@/request/common'
Vue.prototype.request = request;

// IE10以下提示信息
import '@/utils/ie10-tips.js';

Vue.config.productionTip = false
Vue.config.ignoredElements = ['wx-open-launch-weapp']

// 引入hotcss
import 'amfe-flexible/index.js'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
