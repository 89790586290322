import axios from '@/request'
import userApi from '@/request/apiList'
/**
 * 获取路由地址
 * @param {Object} key  例如 user.delete 
 **/
function __objParamsSort(key) {
  let saveKey = ''
  let keyArr = key.split('.')
  let l = keyArr.length
  if (l >= 2) {
    // 使用本地存储时，保存的键名
    saveKey = keyArr[0]
    // 多级更新字段时，需要遍历找到userApi内对应字段的属性（子元素，例如：a.b.c.d 需要遍历找到userApi内a属性的d元素）
    let obj = userApi[saveKey]
    for (let i = 1; i < l - 1; i++) {
      obj = obj[keyArr[i]]
    }
    // 取最终要更新的字段名
    let k = keyArr[l - 1]
    // 更新userApi对应字段
    return obj[k]
  } else {
    // 更新userApi对应字段
    return userApi[key]
  }
}

// 通用请求post 
export function post(url,parameter,costom) {
  let actionUrl = __objParamsSort(url);
  if(!actionUrl){
    return Promise.reject('请求接口错误，请查看url参数');
  }
  return axios({
    url:actionUrl ,
    method: 'post',
    data: parameter,
    costom
  })
}

// 通用请求get
export function get(url,parameter,costom) {
  let actionUrl = __objParamsSort(url);
  if(!actionUrl){
    return Promise.reject('请求接口错误，请查看url参数');
  }
  return axios({
    url: actionUrl,
    method: 'get',
    params: parameter,
    costom
  })
}