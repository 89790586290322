const getType = (value) => {
    const resultStr = Object.prototype.toString.call(value)
    return (resultStr.substring(8, resultStr.length - 1))
}

const isArray = (value) => {
    return (getType(value) === 'Array')
}

const isString = (value) => {
    return (getType(value) === 'String')
}

const isBool = (value) => {
    return (getType(value) === 'Boolean')
}

const isObject = (value) => {
    return (getType(value) === 'Object')
}

const isNumber = (value) => {
    return (getType(value) === 'Number')
}

const isNull = (value) => {
    return (getType(value) === 'Null')
}

const isUndefined = (value) => {
    return (getType(value) === 'Undefined')
}

const isFunction = (value) => {
    return (getType(value) === 'Function')
}

module.exports = {
    getType,
    isArray,
    isString,
    isBool,
    isObject,
    isNumber,
    isNull,
    isUndefined,
    isFunction
}
