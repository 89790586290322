export default {

   'UnifiedLogin':'token/UnifiedLogin',
   'GetUserInfoByUserId': 'api/GetUserInfoByUserId', // token取用户信息

   'GetBanner':'api/GetBanner', // 获取banner
   'GuessLikeExperts':'user/GuessLikeExpertsV3', //感兴趣的专家
   'GetNews':'api/GetNews', //热点资讯
   'GetNewsDetail':'api/GetNewsDetail', //资讯详情
   'GetExeLikeCollect':'api/GetExeLikeCollect', //热点资讯点赞收藏
   'BigGetNews':'news/GetNews', //大咖动态
   'GuideList':'medlive/GuideList', //临床指南
   'GuideDetail':'medlive/GuideDetail', //临床指南详情
   'GuidelineDownUrl':'/medlive/GuidelineDownUrl', //临床指南下载
   'CommitteeList':'meeting/CommitteeList', // 机构号、商户列表
   'GetMeetingList':'api/GetMeetingList', //本月会议
   'PeriodicalList':'periodical/PeriodicalList', //期刊杂志
   'PeriodicalDetail':'periodical/PeriodicalDetail', //杂志详情
   'PeriodicalPaper':'periodical/PeriodicalPaper', //刊次列表
   'PeriodicalPaperDetail':'periodical/PeriodicalPaperDetail', //杂志文章详情
   'GetLiveList':'live-api/GetLiveList', //直播列表
   'GetRegion':'api/GetRegion', //获取省份
   'GetYear':'api/GetYear', //获取年份
   'NewSubject': 'api/NewSubject', //获取学科
   'UpdateNewSubject':'api/UpdateNewSubject', //更新学科
   'CommitteeType':'meeting/CommitteeType',//机构号列表筛选条件
   'getOrgIndex': '/committee/OrgIndex', // 机构号首页无限下拉列表接口
   'ExpertsData':'user/ExpertsData', //资讯详情专家信息

   'ExpertsListNew':'user/ExpertsListNew',//专家排行榜
   'YsList':'user/YsList',//院士风采
   'ExpertsListV3':'user/ExpertsListV3',  // 所有专家
   'AllCurrentChairman':'cma/AllCurrentChairman',//学科组委
   'CmaAbbreviation':'cma/CmaAbbreviation',//学科组委tab筛选
   'PeriodList':'cma/PeriodList',//学科检索
   'PeriodMember':'cma/PeriodMember',//学科组委详情页
   'ExportFollow':'user/FollowExpertsListNew', // 我的关注
   'PopularityExperts':'user/PopularityExperts', // 本周人气专家

   'PeriodicalGetNews':'api/GetNews', //约稿列表
   'AbstractMeeting':'api/AbstractMeeting', //会议论文
   'AbstractClass':'api/AbstractClass', // 会议相关论文分类
   'GetMeetingPaper':'api/GetMeetingPaper', //会议论文相关列表
   'AbstractSearch':'meeting/AbstractSearch', //摘要数据库列表
   'GetAbstractDetail':'api/GetAbstractDetail', //论文详情
   'GetRelated':'api/GetRelated', //论文相关
   'GetResourceCategoryList':'video/GetResourceCategoryList', //视频资源分类
   'GetCategoryVideoList':'video/GetCategoryVideoList', //分类下的视频资源(指南解读等)

   'GetVideoNew': 'api/GetVideoNew', // 最新回放视频列表
   'GetRecommendedLive': 'live-api/GetRecommendedLive', //推荐直播列表
   'GetRecommendLives':'live-api/GetRecommendLives', //推荐直播列表新
   'AppletAppointment':'live-api/AppletAppointment', //预约直播
   'GetVideoListNew':'api/GetVideoListNew', // 最新回放
   'GetVideoLike': 'video/GetVideoLike', // 推荐视频
   'GetSpecialInfo':'video/GetSpecialInfo', // 专题信息
   'GetSpecialInfoNew':'video/GetSpecialInfoNew', // 专题信息-新

   'GetWonderfulVideo':'video/GetWonderfulVideo', // 专题视频
   "getSpecialList":'committee/SpecialList', // 专题列表
   'GetGuessLike':'video/GetGuessLike', //视频专题
    'GetRemmondVideoList':'video/GetRemmondVideoList', //推荐回放

   'GetVieoPlayParamsByVid':'video/GetVieoPlayParamsByVid',  // 根据vid获取视频相关信息接口
   'GetToken':'video/GetToken',                              // 获取视频加密值验证接口
   'newGetVideoParams': 'video/GetVideoParams', // 获取视频播放器播放相关参数
   'GetVideoAd': 'video/GetVideoAd', // 获取直播广告
   "IsOpenQrcode":"video/IsOpenQrcode",//判断用户观看免费视频是否弹出关注服务号二维码
   'CheckMm': 'live-api/CheckMm', //视频观看密码验证
   'CheckInviteCode': 'live-api/CheckInviteCode', //视频观看邀请码验证
   'UpdateStudyLog': 'api/UpdateStudyLog', //观看视频更新学习时长
   'AddPoint':'points/AddPoint', //增加积分接口
   'GetPointTime':'points/GetPointTime', //获取视频直播观看多久加一次积分接口

   'GetRecommendMeetings':'meeting/GetRecommendMeetings', //相关会议
   'GetRecommendVideos':'video/GetRecommendVideos', //相关视频
   'RelatedGuides':'medlive/RelatedGuides', //相关指南
   'GetRecommendGuides':'medlive/GetRecommendGuides', // 相关指南资讯
   'GetRecommendGuidesV2':'medlive/GetRecommendGuidesV2', // 推荐指南资讯V2
   "GuideRelatedVideo":"video/GuideRelatedVideo",//获取指南相关视频接口

   
   'ExpertsInfoNew':'user/ExpertsInfoNew', //个人信息
   'RecommendFollowExperts':'user/RecommendFollowExperts', // 专家详情页推荐关注专家(专家关注的，当前用户未关注
   'GetAlbumList': 'user/GetAlbumList', // 获取相册列表

   'FollowExperts':'user/FollowExperts', //关注
   'GetVideoInfo':'video/GetVideoInfo', //视频详情
   'GetVideoLikeCollect':'api/GetVideoLikeCollect', //视频收藏/点赞
   'RelatedVideo':'video/RelatedVideo', //视频详情相关视频
   'GetUserVideoInfo':'video/GetUserVideoInfo', // 专家视频列表
   'ExpertsPaperList':'nv-api/ExpertsPaperList', //专家论文列表
   'GetVideoComment':'video/GetVideoComment', //视频评论
   'AddCommentVideo':'api/AddComment', //添加视频评论

   'GetOpenVideoMeetingLive':'meeting/GetOpenVideoMeetingLive', //机构号会议回放
   'MeetingPictureList':'meeting/MeetingPictureList', //会议相册列表
   'MeetingPicture':'meeting/MeetingPicture', //会议相册列表详情
   'CommitteeDetail':'meeting/CommitteeDetail', //机构号详情
   'FollowCommittee':'meeting/FollowCommittee', //关注机构号
   "getMeetingVideoList":"video/get-meeting-video-list",//获取日程讲者模式下的会议视频列表
   "getMeetingVideoFilter":"video/get-meeting-video-filter",//获取会议视频列表筛选条件接口
   'CertSearch':'meeting/CertSearch', //继教学分证书下载信息

   'commit':'wxplat/wx-interact/commit', //三方小程序测试提交体验版
   'qrcode':'wxplat/wx-interact/get-experience-qrcode', //三方小程序测试生成体验版二维码
   'getApiConfig':"api/GetApiConfig", // 获取api配置

   'openWxapp':'https://mm.sciconf.cn/ajax/get-weixin-jsapi', //跳转微信小程序


   "BranchList":"learn/BranchList",//省份查询分会名单
   "ProvinceSpecialty":"learn/ProvinceSpecialty",//省市学会/专科专业分会查询
   "BranchDetails":"learn/BranchDetails",//学会详情
   "RegionBranchDetails":"learn/RegionBranchDetails",//学会详情-专业专科
   "RecentTermChange":"learn/RecentTermChange",//最新换届
   "MembersDetails":"learn/MembersDetails",//委员详情名单
   "ExpertSearch":"learn/ExpertSearch",//姓名搜索
   "ExpertSearchDetails":"learn/ExpertSearchDetails",//姓名搜索详情
   "BranchDetailsPc":"learn/BranchDetailsPc",//学会详情PC
   "ExpertSearchPc":"learn/ExpertSearchPc",//姓名搜索PC
   "UserJobDetails":"learn/UserJobDetails",//职务详情

   /*************************  专题相关  **************************/
   'TopicList':'topic/Topic', //专题列表
   'TopicDetail':'topic/TopicDetail', //专题详情
   'TopicNavList':'topic/NavList', //导航列表
   'TopicNewsList':'topic/NewsList', //新闻列表
   'TopicGuideList':'topic/GuideList', //指南列表
   'TopicAbstractList':'topic/AbstractList', //论文列表
   'TopicJournalList':'topic/JournalList', //期刊列表
   'TopicExpertList':'topic/ExpertList', //专家列表
   'TopicOrgList':'topic/OrgList', //机构列表
   'getTopMeetingImg': '/api/GetTopMeeting', // 机构号首页轮播图
   'TopicMeetingList':'topic/MeetingList', //会议列表
   'TopicVideoList':'topic/VideoList', //视频列表
   'TopicEnum':'topic/Enum', //视频自定义分类
   'TopicOrgDetail':'topic/OrgDetail', //机构详情
   'TopicDressSort':'topic/DressSort', //机构详情
   'TopicNavSort':'topic/NavSort', //页面布局排序列表
   'TopicNewsDetail':'topic/NewsDetail', //新闻详情
   'TopicVideoDetail':'topic/VideoDetail', //视频访问添加数量
   'TopicGuideDetail':'topic/GuideDetail', //指南访问添加数量
   'TopicAbstractDetail':'topic/AbstractDetail', //论文访问添加数量
   'TopicJournalDetail':'topic/JournalDetail', //期刊访问添加数量
   'TopicExpertDetail':'topic/ExpertDetail', //专家访问添加数量
   'TopicMeetingDetail':'topic/MeetingDetail', //会议访问添加数量
   'TopicImgList':'topic/LbList', //广告图片列表


   /*************************  企业号  **************************/
   "CommitteeProductType" : "points/CommitteeProductType",//产品类别
   "CommitteeProductList":"points/CommitteeProductList",//产品列表
   "CommitteeProduct":"points/CommitteeProduct",//产品详情
   "CommitteeRecruit":"points/CommitteeRecruit",//招聘列表
   "CommitteeRecruitDetail":"points/CommitteeRecruitDetail",//招聘详情
   "CommitteeStatus":"points/CommitteeStatus",//医企秀入驻状态
   'address':'https://files.sciconf.cn/modules/address.js?v=3.8.9', //地址
   'GetSubject': 'api/GetSubject', // 获取会议全部学科
   'UploadAvatar':'https://apifc.sciconf.cn/yv2/user/UploadAvatar', //上传图片
   "ApplyCommittee":"points/ApplyCommittee",//医企秀入驻
   'getCompanyIndex': '/committee/CompanyIndex', // 企业号首页无限下拉列表接口

   /*************************  精品课堂  **************************/
   'getPremiumVideoList': 'online-premium-video/GetPremiumVideoList',     // 精品课堂列表
   'getCoursesList' : 'online-premium-series-courses/GetCoursesList',     // 获取精品课程列表
   'getPremiumVideoInfo': 'online-premium-video/GetPremiumVideoInfo',     // 获取精品课程信息接口
   'getHotVideoList' : 'online-premium-video/HotVideoList',               // 获取本周热播视频列表接口
   'getCourseListDir' : 'online-premium-series-courses/GetCourseList',    // 课程目录
   'getCourseInfo' : 'online-premium-series-courses/GetCoursesInfo',      // 获取课程详情
   'getOnlineCommentList' : 'online-premium-video/GetOnlineCommentList',  // 获取课程评论
   'addOnlineComment':'online-premium-video/AddComment',                  // 发表评论
   'addOnlineCollect':'online-premium-video/AddCollect',                  // 收藏
   'getUserVideoList' : 'online-premium-video/GetUserVideoInfo',          // 获取作者相关视频
   'getRelatedVideo' : 'online-premium-video/GetRelatedVideo',            // 获取相关视频
   'createOnlineOrder' : 'online-premium-order/Order',                    // 生成订单
   'onlinePay' : 'online-premium-order/Pay',                              // 调起收银台
   'getOnlineOrderQuery' : 'online-premium-order/OrderQuery',              // 主动查询

   /********************  科技奖  *************************************/
   "GetCommittees" : "api/GetCommittees",//获取有奖项的医学会列表
   "GetCommitteeAwards" : "api/GetCommitteeAwards",//获取医学会下的奖项
   'GetAwardsCompany':'api/GetAwardsCompany',//获取科技奖单位
   'GetAwards': 'api/GetAwards', //奖项
   'GetProjectAwards':'api/GetProjectAwards',//项目列表
   "GetAwardsConditions" : "api/GetAwardsConditions",//获取奖项条件
}