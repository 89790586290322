import Vue from 'vue'
import Vuex from 'vuex'
import * as request from '@/request/common'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
    baseUrl:'', // 请求域名
    navList:[],//综合专题导航列表
    specialInfo:null, //综合专题详情
    companyDetail:null, //企业号详情
    company_id:'', //企业号id
    subjectData:[], // 运营学科
    transcriptList: [] // 视频文字稿列表
  },
  getters: {
    userInfo: state => state.userInfo,
    baseUrl: state => state.baseUrl,
    companyDetail: state => state.companyDetail,
    company_id: state => state.company_id,
    subjectData: state => state.subjectData,
  },
  mutations: {
    /**
     * 更新属性值的方法
     * @param {Object} state
     * @param {Object} o 要更新的对象 例如{userInfo:{name:'1111'}}
     * 如果更新模块下的state属性时,请使用  模块名.属性名 例如模块index的name {'index.name':'aaa'}
     **/
    updateState(state, o) {
      for (let key in o) {
        let saveKey = ''
        let keyArr = key.split('.')
        let l = keyArr.length
        if (l >= 2) {
          // 使用本地存储时，保存的键名
          saveKey = keyArr[0]
          // 多级更新字段时，需要遍历找到state内对应字段的属性（子元素，例如：a.b.c.d 需要遍历找到 state内a属性的d元素）
          let obj = state[saveKey]
          for (let i = 1; i < l - 1; i++) {
            obj = obj[keyArr[i]]
          }
          // 取最终要更新的字段名
          let k = keyArr[l - 1]
          // 更新state对应字段
          obj[k] = o[key]
        } else {
          // 更新state对应字段
          state[key] = o[key]
        }
      }
    },
  },
  actions: {
    // 获取企业号详细信息
    getCompanyDetail({state},id){
        return new Promise(resolve => {
            if (!state.companyDetail || id != state.company_id) {
                request.post("CommitteeDetail", {
                    committee_id:id
                }).then(res => {
                    state.companyDetail = res.data;
                    state.company_id = id
                    resolve(state.companyDetail)
                }).catch(() => {
                    state.company_id = id
                    resolve(state.companyDetail)
                })
            }else{
                resolve(state.companyDetail)
            }
        })
    },

     // 获取运营学科通用数据
     /**
      * 为减少修改直接存了返回对象
      * @param {*} param0 
      * @returns 
      */
     getSubjectList({state}){
      return new Promise(resolve => {
        if (!state.subjectData?.data?.length) {
          request.get('NewSubject').then(res => {
                state.subjectData = res;
                resolve(state.subjectData)
            }).catch(() => {
                resolve(state.subjectData)
            })
        }else{
            resolve(state.subjectData)
        }
    })
     },
  },
  modules: {}
})