HTMLElement.prototype.appendHTML = function(html) {
    var divTemp = document.createElement("div"), nodes = null
        // 文档片段，一次性append，提高性能
        , fragment = document.createDocumentFragment();
    divTemp.innerHTML = html;
    nodes = divTemp.childNodes;
    for (var i=0, length=nodes.length; i<length; i+=1) {
       fragment.appendChild(nodes[i].cloneNode(true));
    }
    this.appendChild(fragment);
    // 据说下面这样子世界会更清净
    nodes = null;
    fragment = null;
};

let ie10TipsHTML = `
    <div style="position:fixed;left:0;top:0;right:0;bottom:0;background:rgba(0,0,0,0.5);" class="flex x-center y-center">
    <div class="t-c" style="padding:30px;border-radius:10px;background-color:#fff;color:#000;font-size:16px;">
    <h2 class="fs28 strong" style="color:#ff6633">系统不支持当前浏览器。</h2>
    <p class="mt20">从兼容性和安全性考虑，请使用谷歌、火狐、IE10及以上浏览器访问系统。</p>
    <p style="margin-top:60px;">若没有安装，请选择击浏览器下载 :</p>
    <div class="tip-down">
                <a style="margin-right:50px;" href="http://www.google.cn/chrome/browser/desktop/index.html">谷歌浏览器</a>
                <!-- <a style="margin-left:50px;" href="/iip/usermgr/lib/browsers/ChromeStandalone_Setup.exe">本地下载</a> -->
                <!-- <br> -->
                <a href="http://www.firefox.com.cn/">火狐浏览器</a>
                <!-- <a style="margin-left:50px;" href="/iip/usermgr/lib/browsers/Firefox_setup.exe">本地下载</a> -->
            </div>
    </div>
    </div>
`;
var ua = navigator.userAgent.toLocaleLowerCase();
var browserType = "", browserVersion = "";
if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
  browserType = "IE";
  browserVersion = ua.match(/msie ([\d.]+)/) != null ? ua.match(/msie ([\d.]+)/)[1] : ua.match(/rv:([\d.]+)/)[1];
  if ((1 * browserVersion) <= 10) {
    //ie10以下版本浏览器
    document.getElementsByTagName('body')[0].appendHTML(ie10TipsHTML);
  }
}

