import axios from 'axios'
import storage from 'store'
import store from '@/store'
import * as judgeType from '@/utils/judgeDataType'
import {
    message,
    Modal
} from 'ant-design-vue'
import {
    committee_id,
    source,
    key
} from '@/config';
import NProgress from 'nprogress' // 引入nprogress插件
import 'nprogress/nprogress.css' // 这个nprogress样式必须引入
import qs from 'qs';
let stepNum = 0; // 请求计步器
let pageInitTime = +(new Date()); // 页面初始化时间
let loadingInstance; //创建Loading 的实例
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL // 配置axios请求的地址
// axios.defaults.baseURL = "https://api.juzhanhui.net/"; // 配置axios请求的地址

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


// 获取请求域名
let isGetBaseUrl = false;
const __getBaseUrl = async () => {
    let baseUrl = process.env.VUE_APP_BASE_URL;
    if (!store.getters.baseUrl && !isGetBaseUrl) {
        isGetBaseUrl = true;
        return new Promise(resolve => {
            axios({
                url: "api/GetApiConfig",
                method: 'get',
            }).then(res => {
                baseUrl = res.data.applet_api_url || baseUrl;
                store.commit('updateState', {
                    baseUrl
                });
                resolve(baseUrl)
            }).catch(() => {
                resolve(baseUrl)
            })
        })
    } else {
        resolve(store.getters.baseUrl)
    }
}

// 请求拦截器
axios.interceptors.request.use(async config => {
        let { method,  url } = config;
        // 获取接口域名 ，需要等待
        const isProduction = process.env.NODE_ENV === 'production';
        // if(isProduction){
            if (url !== "api/GetApiConfig" && url != 'meeting/AbstractSearch' && url != 'committee/SpecialList' && !store.getters.baseUrl) {
                let baseUrl = await __getBaseUrl();
                config.baseURL = baseUrl;
            }
            if(store.getters.baseUrl && url != 'meeting/AbstractSearch' && url != 'committee/SpecialList'){
                config.baseURL = store.getters.baseUrl
            }
        // }
        // 计步器加1
        if (stepNum == 0) {
            // 打开loading
            NProgress.start();
        }
        ++stepNum;
        const token = storage.get('auth')
        // 让每个请求携带自定义 token 请根据实际情况自行修改
        if (url != 'https://mm.sciconf.cn/ajax/get-weixin-jsapi') {
            if (token) {
                config.headers['auth'] = token
            }
            config.headers['key'] = key;
            config.headers['committeeID'] = committee_id;
            config.headers['source'] = source;
        }
        return config
    },
    error => {
        return Promise.error(error);
    });


axios.interceptors.response.use(
    response => {

        // 计步器减一
        --stepNum;
        if (stepNum == 0) {
            // 关闭loading
            NProgress.done();
        }
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if (response.status === 200) {
            // 统一处理非200提示
            let {
                code,
                message,msg,Message
            } = response.data
            message = message || msg || Message;
            let custom = response.config.costom || {}
            // hideModal 如果为true则隐藏错误弹框
            if (code != 200 && message && !custom.hideModal) {
                Modal.error({
                    title: '提示',
                    content: message,
                    okText: '确定'
                })
            }
            return Promise.resolve(response.data);
        } else {
            return Promise.reject(response.data);
        }
    },
    // 服务器状态码不是2开头的的情况
    // 这里可以跟你们的后台开发人员协商好统一的错误状态码
    // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
    // 下面列举几个常见的操作，其他需求可自行扩展
    error => {

        // 错误后关闭所有loading
        // 计步器减一
        --stepNum;
        if (stepNum == 0) {
            // 关闭loading
            NProgress.done();
        }

        if (error.response && error.response.status) {
            switch (error.response.status) {
                // 401: 未登录
                // 未登录则跳转登录页面，并携带当前页面的路径
                // 在登录成功后返回当前页面，这一步需要在登录页操作。
                case 401:
                    let custom = error.response.config.costom || {}
                    if (!custom.hideModal) {
                        message.error("身份验证失败");
                        Modal.info({
                            title: '温馨提示',
                            content: '您尚未登录，是否立即登录',
                            okText: '立即登录',
                            onOk() {
                                window.location.replace("https://www.sciconf.cn/unified/login?return_url=" + encodeURIComponent(window.location.href))
                            },
                        })
                    }
                    break;

                    // 403 token过期
                    // 登录过期对用户进行提示
                    // 清除本地token和清空vuex中token对象
                    // 跳转登录页面
                case 403:
                    message.error("登录过期，请关闭重新进入。");
                    // 清除token
                    break;

                    // 404请求不存在
                case 404:
                    message.error("您访问的网页不存在。");
                    break;

                    // 404请求不存在
                case 500:
                    message.error("数据加载出错");
                    break;
                    // 其他错误，直接抛出错误提示
                default:
                    message.error(error.response.data.message);
            }
            return Promise.reject(error.response);
        }
    }
);
export default axios;