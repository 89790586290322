<template>
    <div class="layout-mobile-footer flex x-around">
        <div @click="handleClickEvent(item.path)" class="t-c nav-item" :class="selectedStyle(item.path) ? 'selected' : ''" v-for="(item,index) in navList" :key="index">
            <img class="nav-item-image" :src="selectedStyle(item.path) ? item.icon_hover : item.icon" alt="">
            <p class="nav-item-name">{{item.name}}</p>
        </div>
    </div>
</template>

<script>
import { personal_url } from '@/config/index';
export default {
    name: "layoutMobileNav",
    components: {},
    props: {

    },
    data() {
        return {
            navList : [{
                    name: '首页',
                    path: '/index',
                    icon: 'https://files.sciconf.cn/asset/images/tabBar/tabBar-home.png',
                    icon_hover: 'https://files.sciconf.cn/asset/images/tabBar/tabBar-home-hover.png',
                },
                {
                    name: '会议',
                    path: '/meeting-list',
                    icon: 'https://files.sciconf.cn/asset/images/tabBar/tabBar-meeting.png',
                    icon_hover: 'https://files.sciconf.cn/asset/images/tabBar/tabBar-meeting-hover.png',
                },
                {
                    name: '直播',
                    path: '/live-list',
                    icon: 'https://files.sciconf.cn/asset/images/tabBar/tabBar-live.png',
                    icon_hover: 'https://files.sciconf.cn/asset/images/tabBar/tabBar-live-hover.png',
                },
                {
                    name: '视频',
                    path: '/video-class',
                    icon: 'http://files.sciconf.cn/asset/images/tabBar/online.png',
                    icon_hover: 'http://files.sciconf.cn/asset/images/tabBar/online-hover.png',
                },
                {
                    name: '我的',
                    path: personal_url+'member/512',
                    icon: 'https://files.sciconf.cn/asset/images/tabBar/tabBar-user.png',
                    icon_hover: 'https://files.sciconf.cn/asset/images/tabBar/tabBar-user-hover.png',
                },
            ]
        }
    },
    methods: {
        // 选中
        selectedStyle(path) {
            let currPath = this.$route.fullPath;
            currPath = decodeURI(currPath);
            return path == currPath;
        },
        // 跳转
        handleClickEvent(path) {
            if (path.indexOf("http") != -1) {
                window.location.href = path;
            } else {
                this.$router.push({
                    path,
                });
            }
        },
    },
    mounted() {},
}
</script>

<style lang="less" scoped>
.layout-mobile {
    &-footer{
        box-shadow:0 0 5px 0px #ddd;
        background: #fff;
        position: fixed;
        bottom:0;
        left:0;
        right:0;
        height:60px;
        z-index: 9;
        .nav-item{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1;
            &-image{
                width:22px;
                height:22px;
                display: block;
                object-fit: cover;
            }
            &-name{
                font-size:14px;
                line-height: 1.5;
                color:#333;
                margin-bottom:0;
            }
            &.selected{
                .nav-item-name{
                    color:@theme;
                }
            }
        }
    }
}
</style>