<template>
    <div id="app">
        <a-config-provider :locale="locale">
            <keep-alive :include="aliveRoutes">
                <router-view />
            </keep-alive>
        </a-config-provider>
        <a-back-top :visibility-height="1000">
            <div class="scroll-top flex y-center flex-column x-center">
                <a-icon type="up" />
                <div>置顶</div>
            </div>
        </a-back-top>
        <select-subject v-if="showSelected"></select-subject>
        <layout-mobile-nav class="layout-mobile-footer"></layout-mobile-nav>
    </div>
</template>
<script>
import layoutMobileNav from '@/components/layout/layout-mobile-nav.vue';
import selectSubject from './components/select-subject'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import storage from 'store'
import { mapState } from 'vuex';
export default {
    data() {
        return {
            showSelected: false,
            locale: zhCN,
            aliveRoutes: ['Index', 'InfomationList', 'MeetingList', 'JournalArticle','ExpertIndex', 'ExpertYsMien', 'ExpertCommittee', 'OrganzationIndex', 'InterviewList', 'InviteList', 'VideoClass', 'PeriodicalList', 'MeetingVideoList','JobWarehouseIndex'],

        };
    },
    components: {
        selectSubject,
        layoutMobileNav
    },
    computed: {
        ...mapState(['userInfo'])
    },
    created() {
        if (!storage.get('tid')) {
            const tid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            })
            storage.set('tid', tid)
        }
        // let ua = navigator.userAgent.toLowerCase()
        // if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i)) {
        //     this.$router.replace({
        //         path: '/open-in-mobile',
        //     })
        // }
        setTimeout(()=>{
            if (storage.get('auth') && this.userInfo.operate_subject == 0) {
                this.showSelected = true
                storage.set('showSelected', true)
            }
        },1000)
    },
}
</script>
<style lang="less">
body {
    font-size: 14px !important;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.scroll-top {
    width: 50px;
    height: 50px;
    background: @theme;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
}

// 二维码弹框
.dialog-qrcode {
    .ant-modal-confirm-content {
        margin: 0 !important;
    }

    .ant-modal-confirm-title {
        text-align: center;
    }

    .anticon {
        display: none;
    }

    .ant-modal-confirm-btns {
        float: none !important;
        text-align: center;
    }
}

.layout-mobile-footer{
    display: none!important;
}

@media screen and (max-width:768px){
    .ant-back-top{
        bottom:80px!important;
    }
    #app{
        padding-bottom:60px;
        .layout-mobile-footer{
            display: flex!important;
        }
        // 因为整体页面字体较大，故需要整体调小
        .fs20{
            font-size:16px;
        }
        .fs18{
            font-size:15px;
        }
        .fs16{
            font-size:14px;
        }
        .fs14{
            font-size:13px;
        }
    }
}

// loading
// #nprogress{
//     pointer-events: inherit!important;
// }
// #nprogress .spinner{
//     top:0!important;
//     right:0!important;
//     bottom:0;
//     left:0;
//     background:rgba(0,0,0,0.1);
//     display: flex!important;
//     align-items: center;
//     justify-content: center;
//     .spinner-icon{
//         width: 50px;
//         height: 50px;
//     }
// }
</style>
