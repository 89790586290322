<template>
    <div class="select-subject">
        <a-modal title="请选择学科" class="select-subject" :visible="visibles" @cancel="handleCancle">
            <div class="flex x-left flex-wrap">
                <div class="subject-item fs14" :class="[{'selected-item': selectedIndex == index}]" v-for="(item,index) in subjectList" :key="index" @click="handleSelect(index)">
                    {{item.operate_name}}
                </div>
            </div>
            <template slot="footer">
                <span class="subject-footer t-c colorfff" @click="handleOk">确定</span>
            </template> 
        </a-modal>
    </div>
</template>

<script>
import store from '@/store/index'
import { mapState } from 'vuex';
import storage from 'store'
export default {
    name: 'search',
    props: {
        
    },
    data(){
        return {
            subjectList:[],
            selectedIndex: null,
            visibles:false,
        }
    },
    components: {

    },
    computed: {
        ...mapState(['userInfo'])
    },
    watch:{
        $route(){
            if(storage.get('showSelected')){
                this.getSubjectList()
            }
        },
        deep:true,
    },
    created(){
        if(storage.get('showSelected')){
            this.getSubjectList()
        }
    },
    methods: {
        getSubjectList(){
            this.$store.dispatch('getSubjectList').then(res=>{
                this.subjectList = res.data
                this.visibles = true 
            })
        },
        handleSelect(index){
            this.selectedIndex = index
        },
        handleOk(){
            let id = this.subjectList[this.selectedIndex].id
            this.request.post('UpdateNewSubject',{operate_subject:id}).then(res=>{
                if(res.code == 200){
                    this.visibles = false
                    this.userInfo.operate_subject = id
                    storage.set('showSelected',false)
                    store.commit('updateState',{userInfo:this.userInfo});
                }
            })
        },
        handleCancle(){
            this.visibles = false
        }
    },
    mounted() {

    },
}
</script>
<style lang="less" scoped>
.select-subject {
    /deep/.ant-modal{
        top: calc((100% - 678px)/2);
        padding-bottom: 0;
    }
    /deep/.ant-modal-header{
        border-bottom: 0;
    }
    /deep/.ant-modal-footer{
        border-top: 0;
    }
    .subject-item{
        padding: 5px 15px;
        border-radius: 3px;
        display: inline-block;
        margin: 5px 10px;
        border: 1px solid #ddd;
    }
    .subject-item:hover{
        border: 1px solid #4172e5;
        cursor: pointer;
        color: #999999;
    }
    .selected-item{
        background: #4172e5;
        color: #ffffff;
    }
    .subject-footer{
        width: 80%;
        height: 40px;
        background: #4172e5;
        border-radius: 20px;
        display: block;
        margin: 0 auto;
        line-height: 40px;
        cursor: pointer;
    }
}
</style>
